.navbar {
    border-top: 0.5px solid #283035;
    display: flex;
    justify-content: space-around;
    background-color: white;
    margin: 0;
    padding: 0;
    list-style-type: none;
    // Best position ? Quarterback ? Goal Keeper ? Carry ? Support ?
    position: fixed;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height:60px;
    box-sizing: border-box;
    // max-height: 50px;
    i {
        font-size: 20px;
        margin-bottom: 4px;
    }
    a:link,
    a:visited {

        color: black;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
    }

    a:hover,
    a.active {
        color: #00aeef;
    }
    .fas {
        display: flex;
        flex-direction: column;
    }

    p {
        margin: 0;
        font-size: 12px;
    }
}
