#SPDTimesheets {
  min-height: inherit;
  display: flex;
  flex-direction: column;

  #SPDTimeEntries {
    flex-grow: 1;
    padding-bottom:65px;
  }

  #SPDPayPeriod {
      max-width: 750px;
      margin: 50px auto 0;
  }

  .payPeriodInfo {
      display: flex;
      justify-content: space-between;
      border-bottom: solid 0.5px #283035;
      padding: 10px;
      text-align: left;
  }

  .payPeriodInfo label,
  .payPeriodDays label,
  .SPDSchedule .missed label {
      font-family: canada-type-gibson, sans-serif;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
  }

  .payperiodSelect,
  .payperiodChangeNote {
    text-align: center;
  }

  .payperiodChangeNote {
    margin-top: 5px;

    button {
      background-color: $colourHeading;
      border-radius: 10px;
      color:#fff;
      padding:3px 10px;
      font-family: canada-type-gibson, sans-serif;
      font-weight: bold;
    }
  }

  .payperiodSelect {
    font-family: canada-type-gibson, sans-serif;
    font-weight: bold;
    margin:15px 0;
  }

  #SPDTimesheeetsPayPeriodOptions {
    margin-bottom:20px;

    .SPDTimesheeetsPayPeriodOption {
      display:grid;
      grid-template-columns: 1fr 100px;
      background-color: #fff;
      padding:10px;
      margin-bottom: 5px;
      align-items: center;

      .select {
        border-radius: 50px;
        font-size: 14px;
        font-weight: bold;
        padding:12px;
        width:100%;
        background-color: $colourPositive;
        color:#fff;
        font-family: Roboto, sans-serif;
      }
    }

  }



  .payPeriodInfo .output {
    font-size: 14px;
    padding: 3px 0;

    &.hours {
      border-radius: 2px;
      color:#fff;
      display: flex;
      align-items: center;
      padding:3px;

      &.invalid {
        background-color: #b00;
      }

      &.valid {
        background-color: #0b0;
      }

      i {
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }

  .payPeriodInfo p {
      margin: 0;
  }

  .payPeriodDays {
      display: grid;
      border-bottom: solid 0.5px #283035;
      padding: 10px;
      margin-bottom:10px;
  }

  .payPeriodDays.week {
      grid-template-columns: repeat(7, 1fr);
  }

  .payPeriodDays > .payPeriodDate {
      text-align: center;
      padding:8px 0;

      &.active {
        @include border-radius(4px);
        background-color: #283035;
        color:#fff;

      }
  }

  .payPeriodDays .date {
      font-size: 20px;
      font-weight: 600;
  }

  .payPeriodDays .hours {
      font-size: 12px;
      font-weight: 700;
  }

  .payPeriodNotes {
      padding: 0 5px 10px;
  }

  .payPeriodNotes .response {
      background-color: #fffbd6;
      color:#715100;
      border-radius: 10px;
      padding: 10px 15px;
      font-size: 14px;
      width: 100%;
      line-height: 1.4;
      box-sizing: border-box;
  }

  .SPDSHeaderDay {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      font-family: canada-type-gibson, sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      color: #fff;
      background-color: #283035;
      margin-bottom: 2px;
  }

  .SPDSchedule {
      display: grid;
      grid-template-rows: 1fr auto;
      background-color: #fff;
      margin-bottom: 5px;
      border-left: 5px solid #fff;
  }

  .SPDSchedule .site {
      font-size: 14px;
      padding: 8px 10px 0;
  }

  .SPDSchedule .timesheet {
      display: grid;
      padding: 10px;
      grid-template-columns: 1fr 1fr minmax(50px, auto) 100px;
      grid-column-gap: 5px;
      align-items: center;
      box-sizing: border-box;
  }

  .SPDSchedule .timesheet input,
  .SPDSchedule .timesheet button {
      width: 100%;
      display: block;
      box-sizing: border-box;
      margin-bottom: 0;
      height: 40px;
      box-sizing: border-box;
  }

  .SPDSchedule .timesheet input,
  .SPDSchedule .timesheet .time {
      background-color: #fafafa;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 40px;
      border: solid 1px #f1f1f1;
      border-radius: 5px;
      padding: 0 5px;
  }

  .SPDSchedule .timesheet .hours {
      text-align: right;
  }

  .SPDSchedule .timesheet .status {
      font-size: 14px;
  }

  .SPDSchedule .timesheet .status .result {
      border-radius: 60px;
      background-color: #fafafa;
      padding: 12px;
      text-align: center;
      font-weight: bold;
      color: #283035;
      font-size: 14px;
  }

  .SPDSchedule .timesheet .status .result.approved {
      background-color: #eaf6ec;
      color: #34aa44;
  }

  .SPDSchedule .timesheet .status .result.rejected {
      background-color: #feedee;
      color: #f85359;
  }

  .SPDSchedule .missed {
      padding: 0 8px 8px;
  }

  /* Status */
  .SPDSchedule.schedule,
  .SPDSchedule.schedule.pending,
  .SPDSchedule.shift.pending {
      border-color: #999;
  }

  .SPDSchedule.approved {
      border-color: #4caf50;
  }

  .SPDSchedule.rejected {
      border-color: #f85359;
  }

  .SPDSchedule.pending {
      border-color: #283035;
  }

  .SPDSchedule.waiting {
      border-color: #f5b13b;
  }

  .SPDScheduleBlank {
      padding: 10px;
      color: #fff;
      background-color: #f53b46;
      font-size: 14px;
  }

  .SPDScheduleBlank .notification {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .SPDScheduleBlank .notification .btn {
      padding: 5px 8px;
  }

  .SPDScheduleBlank .notes {
      width: 100%;
      box-sizing: border-box;
      margin-top: 10px;
  }

  .SPDScheduleBlank .notes textarea {
      width: 100%;
      border: 0;
      min-height: 100px;
      padding: 10px;
      box-sizing: border-box;
      margin: 5px 0;
  }

  .SPDScheduleBlank .notes textarea:focus {
      outline: none;
  }

  .SPDSubmitDay {
      padding: 5px 5px 15px;
  }

  /*
  .SPDSubmitDay .buttonRow {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
  }
  */

  .SPDSubmitDay .incomplete {
    border-radius: 5px;
    border:solid 1px #e9e9e9;
    background-color: #b00;
    padding:10px 12px;
    color:#fff;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0 5px;
  }

}

@import "./SPDTimesheetForm.scss";


@media screen and (max-width: 750px) {

  #SPDTimesheets {

    .emptyPage {
      min-height: 50vh;
    }


  }

}
