#SPDDailyRunsheetJob {

  .job {
      box-sizing: border-box;
      padding: 15px;
      background-color: #283035;
      width: 100%;
      margin-top: 1px;
      display: flex;
      align-items: center;


      .address {
          color: #fff;
          flex-grow: 1;

          h2 {
            font-size:13px;
            margin:4px 0;
          }
      }

      .button {
          width: auto;
          white-space: nowrap;
      }
  }

  .jobTabs {
    display: flex;
    width: 100%;
    align-items: stretch;
    margin:5px 0;

    .tab {
      flex-grow: 1;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size:1rem;
      padding:1rem;
      color:$colourHeading;

      &.active {
        background-color: $colourHeading;
        color:#fff;

      }
    }

  }
}
