#SPDJobHistory {
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    flex-grow: 1;
    align-items: center;
    min-height: inherit;

    .schedules {
      width: 100%;
      max-width: 750px;
      padding:0 15px;
      position: fixed;
      bottom: 15px;
      align-items: center;
      text-align: center;
      box-sizing: border-box;

      .button {
        display: block;
      }
    }

    #Loader {
      margin:25px 0;
    }

    .history {
        width: 100%;
    }

    .SPDJobHistorySchedule {
        display: grid;
        grid-template-columns: 22px 80px 1fr;
        grid-column-gap: 5px;
        padding: 15px;
        margin-bottom: 5px;
        background-color: #ffffff;

        .icon {
            padding-top: 5px;
            color: #283035;
            font-size:12px;
        }

        .detail {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin-left: 10px;
            background-color: inherit;
            color: #283035;

            .section,
            .costCentre,
            .scheduleNotes {
              margin-bottom: 8px;
            }

            .section,
            .costCentre {
              font-size: 12px;
              font-weight: bold;
            }

            .staff {
                color: #555;
                font-size: 12px;
                font-weight: bold;
            }
        }

        .dateBox {
            @include border-radius(5px);
            background-color: #e9e9e9;
            color: #283035;
            padding: 10px;
            text-align: center;
            justify-content: center;
            width: 80px;
            min-height: 70px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            h2,
            h3,
            h4,
            h5 {
                margin: 0;
            }

            h2 {
                font-size: 20px;
            }
        }
    }
}
