#Loader {
    // Centering
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: auto;

    .loader,
    .loading {
        border: 10px solid $colourText;
        border-top: 10px solid $colourNotification;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        animation: spin 2s linear infinite;

        &.small {
            border: 5px solid $colourText;
            border-top: 5px solid $colourNotification;
            width: 35px;
            height: 35px;
        }

        &.tiny {
            border: 4px solid $colourText;
            border-top: 4px solid $colourNotification;
            width: 16px;
            height: 16px;
        }
    }
    .loadingText {
        padding: 30px 0 0 0;
        color:#283035;
        font-weight:500;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
