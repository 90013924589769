form {
  fieldset {
    margin: 0;
    border: 0;
    padding: 0;
  }

  label {
    font-family: canada-type-gibson, sans-serif;
    color: $colourHeading;
    font-size: 0.75rem;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;

    &.required {
        color: $colourNegative;
    }

    + small {
        display: block;
        transform: translateY(-1rem); /* TODO: Look for another position setup for multi line instructions */
        color: $colourText;
    }

    &.no-margin + .reactSelect {
        margin-bottom: 1rem;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="time"],
  select {
    display: block;
    width: 100%;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="time"],
textarea,
select {
  @include border-radius(4px);
  border: solid 1px #d4d6d7;
  font-size: 16px;
  padding: 12px 10px;
  box-sizing: border-box;
  min-height: 45px;
  -webkit-appearance:none;

  &:focus {
    outline:none;
  }
}

textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  resize: none;
}

.formBody {
  min-height: inherit;
  background-color: #fff;
  padding: 20px;
}

.reactSelect {
    input[type="text"] {
        margin: 7.5px 0 !important;
        min-height: auto !important;
    }
}

@media screen and (min-width: 40rem) {
    .formBody {
        /*min-height: calc(100vh - 335px); /* TODO: Change based on page height */
        padding: 25px;
    }
}
