#SPDJobFiles,
#SPDSiteFiles {
  #Loader {
    margin:25px 0;
  }
}

.SPDFileFolder {
  background-color: $colourHeading;
  color:#fff;
  display:grid;
  grid-template-columns: 25px 1fr;
  padding:0.5rem;
  align-items: baseline;
  font-size:13px;

  .folderName {

    font-weight: 700;
    font-family: canada-type-gibson, sans-serif;
  }
}

.SPDFileAttachment {
  margin: 5px 0;
  background-color: #fff;

  .downloading {
    padding: 1rem;
    font-size:14px;
  }

  button {
    text-decoration: none;
    color:$colourHeading;
    display: flex;
    align-items: center;
    font-size:14px;
    font-family: "Roboto", sans-serif;
    width: 100%;
    padding: 1rem;

    .fas {
      font-size:10px;
      margin-right: 5px;
    }
  }
}
