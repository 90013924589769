html,
body {
  background-color: #e9e9e9;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}
body,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
}

.text-center {
  text-align: center;
}

.App {
  min-height: calc(100vh - 110px); /* Fallback for browsers that do not support Custom Properties */
  min-height: calc((var(--vh, 1vh) * 100) - 110px);
}

@media screen and (min-width: 40rem) {
  .App {
    min-height: calc(100vh - 110px);
  }
}

@import "./components/css/MainStyle.scss";
