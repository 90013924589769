$colourText: #353f44;
$colourHeading: #384548;
$colourNotification: #42bfdc;
$colourPositive: #34aa44;
$colourNegative: #f85359;

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

main {
  margin: 50px auto 0;
  max-width: 750px;
  min-height: inherit;
  box-sizing:border-box;
}

.text-center {
  text-align: center;
}

.no-margin {
  margin-top:0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.SPDHeader {
    background-color: #283035;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    align-items: center;
    color: white;
    font-size: 10px;
    font-weight: 300;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    text-align: center;
    z-index: 10;

    h1 {
        font-weight: 300;
        font-size:14px;
        margin: auto;
        line-height: 50px;
    }

    .logout {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        line-height: 50px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size:10px;
    }
}


.kmanAlert {
  .swal2-title {
    font-size:18px;
    margin:0;
  }

  .swal2-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }

  .button:focus {
    box-shadow: none !important;
    outline: none;
  }

}

.emptyPage {
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.pageStatusIcon {
  font-size:100px;
  color:#283035;
  margin-bottom:15px;

  + small {
    color:#283035;
    font-weight:500;
  }
}


//Input

@import "./_ui/navbar/Navbar.scss";
@import "./_ui/login/Login.scss";
@import "./_ui/form/Form.scss";
@import "./_ui/loader/Loader.scss";
@import "./_ui/button/button.scss";
@import "./_ui/switch/Switch.scss";

//@import "./SPDExpenses/SPSExpenses.scss";
@import "./SPDDailyRunsheet/SPDDailyRunsheet.scss";
@import "./SPDDailyRunsheet/SPDDailyRunsheetJob.scss";
//@import "./SPDSchedule/SPDSchedule.scss";
@import "./SPDTimesheet/SPDTimesheet.scss";
@import "./SPDWeeklyRunsheet/SPDWeeklyRunsheet.scss";
@import "./SPDJobHistory/SPDJobHistory.scss";
@import "./SPDJobFiles/SPDJobFiles.scss";
@import "./SPDPurchaseOrder/SPDPurchaseOrder.scss";
