// Button
button {
  border:0;
  background: none;
}

.button {
  @include border-radius(5px);
  color: $colourHeading;
  border: solid 1px #e9e9e9;
  border-bottom-width: 1.5px;
  padding: 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-decoration: none;
  box-sizing: border-box;
  min-height:50px;
  line-height: 22px;
  text-align: center;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(248, 248, 248, 1) 100%);
  background: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(248, 248, 248, 1) 100%);
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 50%, rgba(248, 248, 248, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f8f8f8",GradientType=1);
  margin:0;

  &.small {
    padding:8px;
    min-height: 30px;
    font-size:12px;
    line-height:14px;
  }

  &.icon {
      display: inline-flex;
      align-items: center;

      .icon {
          font-size: 14px;
      }
  }

  &.expand {
    display: block;
    width:100%;
  }

  &:active,
  &:focus {
      color: $colourHeading;
  }

  &.positive {
      background: rgb(40, 160, 48);
      background: -moz-linear-gradient(135deg, rgba(40, 160, 48, 1) 50%, rgba(25, 151, 31, 1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(40, 160, 48, 1) 50%, rgba(25, 151, 31, 1) 100%);
      background: linear-gradient(135deg, rgba(40, 160, 48, 1) 50%, rgba(25, 151, 31, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#28a030",endColorstr="#19971f",GradientType=1);
      border-color: #34aa44;
      color: #fff;

      &:active,
      &:focus {
          color: #fff;
      }
  }

  &.negative {
      background: rgb(248, 83, 89);
      background: -moz-linear-gradient(135deg, rgba(248, 83, 89, 1) 50%, rgba(228, 59, 65, 1) 100%);
      background: -webkit-linear-gradient(135deg, rgba(248, 83, 89, 1) 50%, rgba(228, 59, 65, 1) 100%);
      background: linear-gradient(135deg, rgba(248, 83, 89, 1) 50%, rgba(228, 59, 65, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f85359",endColorstr="#e43b41",GradientType=1);
      border-color: #f85359;
      color: #fff;

      &:active,
      &:focus {
          color: #fff;
      }
  }

  &.action {
    background: #00aeef;
    color: #fff;

    &:active,
    &:focus {
        color: #fff;
    }
  }
}
