#login {
  box-sizing: border-box;
  padding:0 20px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    text-align: center;
    padding:25px 15px;

    img {
      margin: auto;
      min-width: 50%;
      max-width: 75%;
    }

  }

  .response {
    font-size:14px;
    color:#fff;
    border-radius: 5px;
    background-color: rgb(248, 83, 89);
    text-align: center;
    padding:10px 12px;
    margin-bottom: 15px;
  }

  form {
    width:100%;
    max-width:750px;

    .field {
      @include border-radius(4px);
      border: solid 1px #d4d6d7;
      background-color: #fff;
      overflow: hidden;

      input {
        border-radius: 0;
        border:0;
        height:50px;
      }
    }

    .loginPassword {
      position: relative;

      .togglePassword {
        position: absolute;
        width:50px;
        height:50px;
        top:0;
        right:0;
        text-align: center;
        line-height: 50px;
        color:#283035;
      }
    }

    input {
      &:focus {
        outline: none;
      }
    }
  }
}

@media screen and (min-width: 751px) {

  #login {

    form {

      .logo {
        padding:25px;

        img {
          min-width:200px;
          max-width:300px;
        }

      }
    }
  }
}
