.SPDTimesheetFormSpacer {
  height:10px;
}
#SPDTimesheetForm {
  margin-bottom:10px;

  .timesheetHeader {
    background-color: #283035;
    padding: 10px;
    font-family: canada-type-gibson, sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    color:#fff;
  }

  .response {
    @include border-radius(4px);
    padding:10px 15px;
    margin:8px 5px 0;
    font-size:14px;
    text-align: center;

    &.error {
      background-color: $colourNegative;
      color:#fff;
    }

    &.success {
      background-color: $colourPositive;
      color:#fff;
    }
  }

  form {
    fieldset {
      padding: 15px;
      background-color: #fff;
      border: 0;

      input[type="text"],
      input[type="email"],
      select,
      textarea {
        display: block;
        width: 100%;
      }
    }



    .formRow {

      &.timeRow {
        display: grid;
        grid-template-columns: 1fr 1fr 80px;
        grid-column-gap: 15px;

        .duration {
          text-align: center;

          .durationLabel {
            line-height:45px;
            font-weight: normal;
          }
        }
      }

      &.switchRow {
        display:flex;
        align-items: center;

        label {
          margin: 0 0 0 10px;
        }
        margin-bottom:15px;
      }

      &.actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 8px 5px 15px;
        grid-column-gap: 8px;
      }

      /* Removes the clear button from time inputs */
      input[type="time"]::-webkit-clear-button {
          display: none;
      }

      /* Removes the spin button */
      input[type="time"]::-webkit-inner-spin-button {
          display: none;
      }

      /* Always display the drop down caret */
      input[type="time"]::-webkit-calendar-picker-indicator {
          color: #2c3e50;
      }

    }


  }

  .SPDTimesheetFormAttachment,
  .SPDTimesheetFormFile {
    .filename {
      border-radius: 5px;
      border: solid 1px #d4d6d7;
      display: grid;
      grid-template-columns: 1fr 40px;
      margin-bottom: 10px;
      background-color: #fafafa;
      padding: 10px 0 10px 10px;
      align-items: center;

      .name,
      a {
          color: #283035;
          font-size: 15px;
      }

      a {
          text-align: center;
          padding: 10px;
          box-sizing: border-box;
          line-height: 1em;
      }
    }
  }

}
