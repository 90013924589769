.Switch {
  @include border-radius(50px);
  display: inline-block;
  width:55px;
  height:30px;
  position:relative;

  .toggle {
    @include border-radius(50px);
    width:25px;
    height:25px;
    background-color: #fff;
    position: absolute;
    top:2.5px;
    left:2.5px;
    transition: left 0.25s;
  }

  &.active {
    background-color: $colourPositive;
  }

  &.inactive {
    background-color: $colourNegative;

    .toggle {
      left:27.5px;
    }
  }


}
