#SPDPurchaseOrder {


  .response {
    background-color: #fffbd6;
    color:#715100;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 14px;
    width: 100%;
    line-height: 1.4;
    box-sizing: border-box;
    margin-bottom: 20px;


    &.error {
      background-color: #b00;
      color:#fff;
    }

    &.success {
      background-color: $colourPositive;
      color:#fff;
      font-size:1.75em;
      font-weight: 500;
      text-align: center;
    }
  }

}
