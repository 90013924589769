#SPDDailyRunsheet {
  min-height: inherit;
  display: flex;
  flex-direction: column;


  // Button Change
  #SPDDailyRunsheetDates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    padding: 10px;

    &.day {
      box-sizing: border-box;
      height: 100px;
      font-weight: bold;

      button {
        margin: 0;
        outline: none;
        border: 0.5px solid #d7d9da;

        &.active,
        &:focus,
        &:target,
        &:hover,
        &:visited {
          background: #00aeef;
          color: #ffffff;
        }

        .weekday {
          font-size: 14px;
          font-weight: bold;
        }

        .date {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

  #SPDDailyRunsheetSchedules {
    flex-grow: 1;
    padding-bottom:65px;

    .schedule {
      margin-bottom:5px;
    }

    .address {
      box-sizing: border-box;
      padding: 15px;
      background-color: #283035;
      color: white;
      display: flex;
      align-items: center;

      .addressBox {
        flex-grow: 1;
        h2 {
          font-size:13px;
          margin:4px 0;
        }
      }

      .button {
        width: auto;
        white-space: nowrap;
      }
    }

    .detail {
      padding: 15px 15px 5px;
      background-color: #fff;
    }

    .notesTitle {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .notes,
    .notes *,
    .crew {
      font-size: 14px !important;
    }

    .row {
      display: grid;
      grid-template-columns: 25px 1fr;
      grid-column-gap: 10px;
      margin-bottom: 15px;
      font-size:14px;

      i {
        color: #999;
      }
    }
  }
}
